import LocalStorageUtil, {
  StorageKeyInfo,
} from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import errorStore from "@reservauto/react-shared/stores/errorStore";
import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";
import {
  AvailableFeatureDTO,
  EAvailableFeatureType,
} from "../areas/general/dto";
import { AvailableFeaturesService } from "../areas/general/service";
import branchStore from "../shared/stores/branchStore";

export interface FeatureList {
  features: AvailableFeatureDTO[] | null;
  hasFeature: (feature: EAvailableFeatureType) => boolean;
  hasFeatureDisabled: (feature: EAvailableFeatureType) => boolean;
  hasFeatureEnabled: (feature: EAvailableFeatureType) => boolean;
}

const localStorageKey: StorageKeyInfo<AvailableFeatureDTO[] | null> = {
  key: "AvailableFeature",
  pathIndependant: true,
  ttl: { hours: 4 },
};

export class AvailableFeatureStore extends StateStoreBase<FeatureList> {
  private features: FeatureList = {
    features: null,
    hasFeature: () => false,
    hasFeatureDisabled: () => false,
    hasFeatureEnabled: () => false,
  };

  public clearCache(): void {
    LocalStorageUtil.remove(localStorageKey);
  }

  public get(): FeatureList {
    return this.features;
  }

  public async load(props?: { forceReload?: boolean }): Promise<FeatureList> {
    try {
      let features = LocalStorageUtil.get(localStorageKey, null);

      if (!features || props?.forceReload) {
        if (this.features.features) {
          this.features = { ...this.features, features: null };
          this.notifySubscribers();
        }

        const result = await new AvailableFeaturesService().getAvailableFeature(
          { branchId: branchStore.get().id },
        );
        if (result.features && result.features.length > 0) {
          LocalStorageUtil.set(localStorageKey, result.features);
          features = result.features;
        }
      }

      this.features = {
        features: features,
        hasFeature: (type): boolean =>
          features?.some((f) => f.type === type) ?? false,
        hasFeatureDisabled: (type): boolean =>
          features?.some((f) => f.type === type && f.disabledReason !== null) ??
          false,
        hasFeatureEnabled: (type): boolean =>
          features?.some((f) => f.type === type && f.disabledReason === null) ??
          false,
      };
      this.notifySubscribers();
    } catch (ex) {
      errorStore.showExceptionPage(ex);
    }

    return this.features;
  }
}

const availableFeatureStore = new AvailableFeatureStore();
export default availableFeatureStore;
